import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native';

export function Splash() {
  return (
    <View style={styles.root}>
      <ActivityIndicator size="large"/>
    </View>
  );
}

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
