import React from 'react';
import {lazy, Suspense} from 'react';
import {Routes, Route} from 'extensions/navigation';
import {Splash} from 'interface/base/Splash';
import App from 'interface/layout/App';

const Dashboard = lazy(() => import('interface/routes/Dashboard'));
const Drives = lazy(() => import('interface/routes/Drives'));
const Folder = lazy(() => import('interface/routes/Folder'));
const Feeds = lazy(() => import('interface/routes/Feeds'));
const Room = lazy(() => import('interface/routes/Room'));

export function Navigator() {
  return (
    <Routes>
      <Route path="/" element={<App/>}>
        <Route index element={
          <Suspense fallback={<Splash/>}>
            <Dashboard/>
          </Suspense>
        }/>
        <Route path="feed" element={
          <Suspense fallback={<Splash/>}>
            <Feeds/>
          </Suspense>
        }>
          <Route path=":uri" element={
            <Suspense fallback={<Splash/>}>
              <Feeds/>
            </Suspense>
          }/>
        </Route>
        <Route path="drive" element={
          <Suspense fallback={<Splash/>}>
            <Drives/>
          </Suspense>
        }>
          <Route path=":path" element={
            <Suspense fallback={<Splash/>}>
              <Folder/>
            </Suspense>
          }/>
        </Route>
        <Route path="chat" element={
          <Suspense fallback={<Splash/>}>
            <Drives/>
          </Suspense>
        }>
          <Route path=":path" element={
            <Suspense fallback={<Splash/>}>
              <Room/>
            </Suspense>
          }/>
        </Route>
      </Route>
    </Routes>
  );
}
