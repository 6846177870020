import {t} from '@lingui/macro';
import {useToast} from 'react-native-toast-notifications';
import {useRef, useEffect, useState, useCallback} from 'react';
import {useShortcuts} from 'interface/hooks/useShortcuts';
import {useFloating, shift, offset} from '@floating-ui/react-native';
import {Linking, Modal, StyleSheet, View, TextInput, Button, Pressable} from 'react-native';
import {Link, Outlet} from 'extensions/navigation';
import {Avatar} from 'interface/base/Avatar';
// import {useMatrix} from 'interface/hooks/useMatrix';

const iconFolder = require('assets/icons/folder.svg').default;
const iconChats = require('assets/icons/chats.svg').default;
const iconGrid = require('assets/icons/grid.svg').default;
const iconPlus = require('assets/icons/plus.svg').default;
const iconGear = require('assets/icons/gear.svg').default;
const iconRSS = require('assets/icons/rss.svg').default;

export default function() {
  const [_counter, setCounter] = useState(0);
  const [sitePanelVisible, setSitePanelVisible] = useState(false);
  const toast = useToast();
  // const matrix = useMatrix();
  const shortcuts = useShortcuts();
  const sitePanel = useFloating({placement: 'right', middleware: [shift(), offset(16)]});
  const refSiteInput = useRef<any>();

  const openMenu = useCallback(() => console.log('open menu'), []);
  const showAddSite = useCallback(() => setSitePanelVisible(true), []);
  const hideAddSite = useCallback(() => setSitePanelVisible(false), []);
  const openSite = useCallback((url: string) => Linking.openURL(url), []);
  const addSite = useCallback(() => {
    const url: string = refSiteInput.current?.value || '';
    if (url?.startsWith('https://') || url?.startsWith('http://')) {
      shortcuts.push(url);
    } else {
      toast.show(t`Invalid shortcut link`, {type: 'danger'});
    }
    hideAddSite();
  }, [refSiteInput]);

  useEffect(() => {
    setInterval(() => setCounter(Date.now()), 200);
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.menu}>
        <Link style={styles.shortcut} to="/">
          <Avatar title={t`Dashboard`} image={iconGrid}/>
        </Link>
        <Link style={styles.shortcut} to="/drive">
          <Avatar title={t`Drive`} image={iconFolder}/>
        </Link>
        <Link style={styles.shortcut} to="/chat">
          <Avatar title={t`Chat`} image={iconChats}/>
        </Link>
        <Link style={styles.shortcut} to="/feed">
          <Avatar title={t`Feed`} image={iconRSS}/>
        </Link>
        <View style={styles.divider}/>
        <View style={styles.shortcuts}>
          {shortcuts?.map((url, i) =>
            <Pressable
              key={i}
              style={styles.shortcut}
              onPress={() => openSite(url)}
              // @ts-ignore
              onContextMenu={(e) => {
                e?.preventDefault();
                showAddSite();
              }}>
              <Avatar
                full
                title={url}
                image={`https://icon.horse/icon?uri=${url}`}
              />
            </Pressable>
          )}
          {/*matrix?.getRooms()
            .sort((a, b) => a.name.localeCompare(b.name, 'en', {numeric: true}))
            .filter(room => room.getMyMembership() === 'join')
            .map((room) =>
              <Pressable
                key={room.roomId}
                style={styles.shortcut}
                onPress={() => console.log(room)}>
                <Avatar
                  full
                  title={room.name}
                  image={room.getAvatarUrl('https://matrix.org', 54, 54, 'scale')}
                />
              </Pressable>
            )*/}
        </View>
        <Pressable ref={sitePanel.reference} style={styles.shortcut} onPress={showAddSite}>
          <Avatar prompt title={t`Add shortcut`} image={iconPlus}/>
        </Pressable>
        <View style={styles.flex}/>
        <Pressable style={styles.shortcut}>
          <Avatar title={t`Feeds`} image={iconGear}/>
        </Pressable>
      </View>
      <Outlet context={[openMenu]}/>
      <Modal transparent visible={sitePanelVisible}>
        <View style={{flex: 1}} onResponderGrant={hideAddSite} onStartShouldSetResponder={() => true}/>
        <View ref={sitePanel.floating} style={[styles.sitePanel, {
          top: sitePanel.y ?? 0,
          left: sitePanel.x ?? 0,
          opacity: sitePanel.x && sitePanel.y ? 1 : 0,
        }]}>
          <TextInput
            ref={refSiteInput}
            style={styles.input}
            placeholder={t`Website address`}
            placeholderTextColor="#9D9D9D"
            onSubmitEditing={addSite}
          />
          <View style={styles.actions}>
            <Button title={t`Save`} onPress={addSite} color="#0071CA"/>
            <View style={styles.spacer}/>
            <Button title={t`Close`} onPress={hideAddSite} color="#46494F"/>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
  },
  menu: {
    width: 64,
    paddingTop: 10,
    borderRightWidth: 1,
    alignItems: 'center',
    borderColor: '#272729',
    backgroundColor: '#070707',
  },
  divider: {
    height: 2,
    width: 32,
    borderRadius: 10,
    marginVertical: 4,
    backgroundColor: '#FFFFFF',
    opacity: 0.2,
  },
  shortcuts: {
    overflow: 'scroll',
    paddingHorizontal: 2,
    paddingTop: 2,
    marginTop: 4,
    flexShrink: 1,
  },
  shortcut: {
    borderRadius: 10,
    marginBottom: 8,
  },
  sitePanel: {
    width: 280,
    padding: 12,
    marginTop: 6,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#363636',
    backgroundColor: '#1E1F21',
    position: 'absolute',
  },
  input: {
    paddingVertical: 12,
    paddingHorizontal: 14,
    borderRadius: 3,
    marginBottom: 6,
    fontSize: 14,
    color: '#FFFFFF',
    backgroundColor: '#000000',
  },
  spacer: {
    width: 6,
  },
  flex: {
    flex: 1,
  },
  actions: {
    marginTop: 6,
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
  },
});
