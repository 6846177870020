import {StyleSheet, View, Text, Image} from 'react-native';

export interface AvatarProps {
  title: string,
  image?: string | null,
  badge?: string,
  full?: boolean,
  prompt?: boolean,
  notification?: boolean,
}

export function Avatar(props: AvatarProps) {
  const classes = {
    root: [
      styles.root,
      props.prompt && styles.prompt,
    ],
    avatar: props.full
      ? styles.avatarFull
      : styles.avatar,
  };
  return (
    <View style={classes.root} accessibilityLabel={props.title}>
      {props.badge &&
        <Text style={styles.tipped}>
          {props.badge}
        </Text>
      }
      {props.notification &&
        <View style={styles.notification}/>
      }
      {props.image &&
        <Image
          style={classes.avatar}
          source={{uri: props.image}}
        />
      }
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 46,
    height: 46,
    borderRadius: 10,
    //borderWidth: 1,
    shadowOffset: {height: 2, width: 1},
    shadowOpacity: 0.2,
    shadowColor: '#000000',
    //borderColor: '#363636',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'row',
    backgroundColor: '#1E1F21',
  },
  prompt: {
    borderStyle: 'dashed',
    backgroundColor: 'transparent',
    borderColor: '#FFFFFF',
    borderWidth: 1,
    opacity: 0.2,
  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: 3,
  },
  avatarFull: {
    width: 46,
    height: 46,
    borderRadius: 10,
  },
  tipped: {
    top: 0,
    left: 0,
    fontSize: 12,
    color: '#FFFFFF',
    position: 'absolute',
  },
  notification: {
    top: 0,
    right: 0,
    width: 9,
    height: 9,
    borderRadius: 90,
    backgroundColor: 'red',
    position: 'absolute',
  },
});
