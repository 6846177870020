import {getYjsValue, syncedStore} from '@syncedstore/core';
import {IndexeddbPersistence} from 'y-indexeddb';
import {WebrtcProvider} from 'y-webrtc';
import {v4 as uuidv4} from 'uuid';

let _key = localStorage.getItem('key');
if (!_key) {
  _key = uuidv4();
  localStorage.setItem('key', _key);
}

export const key = _key;

export const store = syncedStore({
  shortcuts: [] as string[],
  feeds: [] as string[],
});

export const doc = getYjsValue(store);

new WebrtcProvider(key, doc as any);
new IndexeddbPersistence(key, doc as any);

export default store;
