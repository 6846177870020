import {useEffect} from 'react';
import {I18nProvider} from '@lingui/react';
import {ToastProvider} from 'react-native-toast-notifications';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {createBrowserHistory, createMemoryHistory} from 'history';
import {i18n, loadLocale} from 'utils/i18n';
import {Navigator} from 'interface/Navigator';
import {Router} from 'extensions/navigation';
import {isWeb} from 'utils/platform';
import device from 'extensions/device';

export function Main() {
  useEffect(() => {
    loadLocale(device.getLocale(true));
  }, []);
  return (
    <ToastProvider>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <SafeAreaProvider>
          <Router history={isWeb() ? createBrowserHistory() : createMemoryHistory()}>
            <Navigator/>
          </Router>
        </SafeAreaProvider>
      </I18nProvider>
    </ToastProvider>
  )
};
